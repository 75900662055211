var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.resources,"size":"small","stripe":""},scopedSlots:_vm._u([{key:"monthly_fee",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"tw-font-bold text-blue"},[_vm._v(" "+_vm._s(_vm.formatCurrencyWithCode(row.monthly_fee, row.currency))+" ")])]}},{key:"deposit_amount",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"tw-font-bold text-blue"},[_vm._v(" "+_vm._s(_vm.formatCurrencyWithCode(row.deposit_amount, row.currency))+" ")])]}},{key:"province_code",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.province ? row.province.name_en : '')+" ")]}},{key:"district_code",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.district ? row.district.name_en : '')+" ")]}},{key:"commune_code",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.commune ? row.commune.name_en : '')+" ")]}},{key:"village_code",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.village ? row.village.name_en : '')+" ")]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('edit')),expression:"$t('edit')"}],staticClass:"text-primary ml-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onEdit(row)}}},[_c('Icon',{attrs:{"type":"ios-create","size":"20"}})],1),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('delete')),expression:"$t('delete')"}],staticClass:"text-danger ml-2",on:{"click":function($event){$event.preventDefault();return _vm.onDelete(row)}}},[_c('Icon',{attrs:{"type":"ios-trash","size":"20"}})],1)]}}])}),_c('Modal',{attrs:{"draggable":"","sticky":"","scrollable":"","mask":false,"footer-hide":true,"width":"1000","title":_vm.$t('branch.officeRental')},model:{value:(_vm.showContract),callback:function ($$v) {_vm.showContract=$$v},expression:"showContract"}},[_c('RentalContract',{ref:"contract_action",on:{"cancel":_vm.clearEdit,"fetchData":_vm.fetchData}}),(_vm.loading)?_c('Spin',{attrs:{"size":"large","fix":""}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }