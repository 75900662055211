<template>
    <div>
        <div class="tw-align-middle">
            <ts-loading-banner :loading="loading">
                <div v-for="(item, index) in model.office_rentals" :key="index">
                    <div class="tw-flex tw-items-center tw-justify-between">
                        <h1>{{ $t('branch.officeRental') }}</h1>
                    </div>
                    <div class="row gx-3 mb-3">
                        <div class="col-md-4 mb-1 ">
                            <label class="mb-1">{{
                                $t('branch.contractStartDate')
                            }}</label>
                            <DatePicker
                                style="width:100%;"
                                :value="item.contract_start_date"
                                placeholder="DD-MM-YYYY"
                                format="dd-MM-yyyy"
                                @on-change="
                                    onChangeContractStartDate($event, item)
                                "
                            />
                        </div>
                        <div class="col-md-4 mb-1">
                            <label class="mb-1">{{
                                $t('branch.contractPeriod')
                            }}</label>
                            <input
                                class="form-control"
                                type="number"
                                v-model="item.contract_period"
                                :placeholder="$t('branch.contractPeriod')"
                                @input="getExpiryDate(item)"
                            />
                        </div>
                        <div class="col-md-4 mb-1 ">
                            <label class="mb-1">{{
                                $t('branch.contractExpiryDate')
                            }}</label>
                            <DatePicker
                                style="width:100%;"
                                :value="item.contract_expiry_date"
                                placeholder="DD-MM-YYYY"
                                format="dd-MM-yyyy"
                                @on-change="
                                    onChangeContractExpiryDate($event, item)
                                "
                            />
                        </div>
                    </div>
                    <div class="row gx-3 mb-3">
                        <div class="col-md-4 mb-1 ">
                            <label class="mb-1">{{
                                $t('branch.currencyId')
                            }}</label>
                            <div class="col-md-7 tw-whitespace-nowrap">
                                <RadioGroup
                                    v-model="item.currency_id"
                                    size="small"
                                >
                                    <Radio
                                        class="mt-1"
                                        border
                                        :label="c.currency_id"
                                        v-for="(c, index) in currencies"
                                        :key="index"
                                    >
                                        {{ c.currency_code }}
                                    </Radio>
                                </RadioGroup>
                            </div>
                        </div>
                        <div class="col-md-4 mb-1 ">
                            <label class="mb-1">{{
                                $t('branch.monthlyFee')
                            }}</label>
                            <Poptip trigger="focus">
                                <Input
                                    style="width:315px;"
                                    v-model.number="item.monthly_fee"
                                    :placeholder="$t('branch.enterNumber')"
                                    ref="monthly_fee"
                                >
                                    <i
                                        class="fas fa-dollar-sign"
                                        slot="prefix"
                                        v-if="item.currency_id == 1"
                                    ></i>
                                    <i
                                        class="far fa-registered"
                                        slot="prefix"
                                        v-if="item.currency_id == 2"
                                    ></i>
                                    <i
                                        class="fas fa-bold"
                                        slot="prefix"
                                        v-if="item.currency_id == 3"
                                    ></i>
                                </Input>
                                <div slot="content">
                                    {{
                                        item.monthly_fee
                                            ? formatNumber(
                                                  item.monthly_fee,
                                                  item.currency_id
                                              )
                                            : $t('branch.enterNumber')
                                    }}
                                </div>
                            </Poptip>
                        </div>
                        <div class="col-md-4 mb-1 ">
                            <label class="mb-1">{{
                                $t('branch.depositAmount')
                            }}</label>
                            <Poptip trigger="focus">
                                <Input
                                    style="width:315px;"
                                    v-model.number="item.deposit_amount"
                                    :placeholder="$t('branch.enterNumber')"
                                    ref="deposit_amount"
                                >
                                    <i
                                        class="fas fa-dollar-sign"
                                        slot="prefix"
                                        v-if="item.currency_id == 1"
                                    ></i>
                                    <i
                                        class="far fa-registered"
                                        slot="prefix"
                                        v-if="item.currency_id == 2"
                                    ></i>
                                    <i
                                        class="fas fa-bold"
                                        slot="prefix"
                                        v-if="item.currency_id == 3"
                                    ></i>
                                </Input>
                                <div slot="content">
                                    {{
                                        item.deposit_amount
                                            ? formatNumber(
                                                  item.deposit_amount,
                                                  item.currency_id
                                              )
                                            : $t('branch.enterNumber')
                                    }}
                                </div>
                            </Poptip>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="tw-mt-3 col-md-4">
                            <label class="mb-1">{{
                                $t('branch.ownerPhoneNumber')
                            }}</label>
                            <input
                                class="form-control"
                                style="width:310px"
                                v-model="item.owner_phone_number"
                                :placeholder="$t('branch.ownerPhoneNumber')"
                            />
                        </div>
                        <div class="col-md-8">
                            <label class="mb-1">{{
                                $t('branch.notificationReceiver')
                            }}</label>
                            <Select
                                v-model="item.notification_receiver"
                                filterable
                                :loading="employeeLoading"
                                :placeholder="$t('branch.searchEmployeeName')"
                                :class="{
                                    'is-invalid': errors.has(
                                        'notification_receiver'
                                    )
                                }"
                                :clearable="true"
                                :multiple="true"
                                :max-tag-count="2"
                                width="100%"
                            >
                                <Option
                                    v-for="(option, index) in employees"
                                    :value="option.employee_id"
                                    :key="index"
                                    >{{ option.employee_name_en }} |
                                    {{ $t('employee.branch') }}:
                                    {{
                                        option.branch
                                            ? option.branch.branch_name_en
                                            : ''
                                    }}
                                </Option>
                            </Select>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('notification_receiver')"
                            >
                                {{ errors.first('notification_receiver') }}
                            </div>
                        </div>
                    </div>
                    <div class="row gx-3 mb-3">
                        <div class="col-md-6">
                            <label class="mb-1">
                                {{ $t('branch.provinceCode') }}
                            </label>
                            <select
                                v-model="item.province_code"
                                class="form-control"
                                @change="provinceChange(item.province_code)"
                                @input="
                                    item.district_code = item.commune_code = item.village_code = null
                                "
                            >
                                <option
                                    :value="null"
                                    selected
                                    disabled
                                    style="display: none;"
                                    >{{ $t('select') }}
                                </option>
                                <option
                                    v-for="(province, index) in provinces"
                                    :key="index"
                                    :value="province.province_code"
                                >
                                    {{ province.name_en }} |
                                    {{ province.name_kh }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <label class="mb-1">
                                {{ $t('branch.districtCode') }}
                            </label>
                            <ts-loading-banner :loading="province_change">
                                <select
                                    v-model="item.district_code"
                                    class="form-control"
                                    @change="districtChange(item.district_code)"
                                    @input="
                                        item.district_code = item.commune_code = item.village_code = null
                                    "
                                >
                                    <option
                                        :value="null"
                                        selected
                                        disabled
                                        style="display: none;"
                                        >{{ $t('select') }}
                                    </option>
                                    <option
                                        v-for="(district, index) in districts"
                                        :key="index"
                                        :value="district.district_code"
                                    >
                                        {{ district.name_en }} |
                                        {{ district.name_kh }}
                                    </option>
                                </select>
                            </ts-loading-banner>
                        </div>
                    </div>
                    <div class="row gx-3 mb-3">
                        <div class="col-md-6 mb-1">
                            <label class="mb-1">
                                {{ $t('branch.communeCode') }}
                            </label>
                            <ts-loading-banner :loading="district_change">
                                <select
                                    v-model="item.commune_code"
                                    class="form-control"
                                    @change="communeChange(item.commune_code)"
                                    @input="item.village_code = null"
                                >
                                    <option
                                        :value="null"
                                        selected
                                        disabled
                                        style="display: none;"
                                        >{{ $t('select') }}
                                    </option>
                                    <option
                                        v-for="(commune, index) in communes"
                                        :key="index"
                                        :value="commune.commune_code"
                                    >
                                        {{ commune.name_en }} |
                                        {{ commune.name_kh }}
                                    </option>
                                </select>
                            </ts-loading-banner>
                        </div>
                        <div class="col-md-6 mb-1">
                            <label class="mb-1">
                                {{ $t('branch.villageCode') }}
                            </label>
                            <ts-loading-banner :loading="commune_change">
                                <select
                                    v-model="item.village_code"
                                    class="form-control"
                                >
                                    <option
                                        :value="null"
                                        selected
                                        disabled
                                        style="display: none;"
                                    >
                                        {{ $t('select') }}
                                    </option>
                                    <option
                                        v-for="(village, index) in villages"
                                        :key="index"
                                        :value="village.village_code"
                                    >
                                        {{ village.name_en }} |
                                        {{ village.name_kh }}
                                    </option>
                                </select>
                            </ts-loading-banner>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label class="mb-1">{{ $t('branch.remark') }}</label>
                        <div class="col-md-6" style="width:970px">
                            <textarea
                                v-model="item.remarks"
                                class="form-control"
                                rows="2"
                                :placeholder="$t('branch.remarks')"
                            ></textarea>
                        </div>
                    </div>
                    <div>
                        <fieldset>
                            <legend class="text-blue">
                                Contract Attachments/ឯកសារភ្ជាប់កិច្ចសន្យា
                            </legend>
                            <div class="row">
                                <div class="col-md-12 tw-h-48">
                                    <VueFileAgent
                                        ref="vueFileAgent"
                                        :theme="'default'"
                                        :multiple="true"
                                        :deletable="true"
                                        :meta="true"
                                        :linkable="true"
                                        :accept="
                                            'image/*,video/*,.pdf,.doc,.docx,.ods'
                                        "
                                        :maxSize="'10MB'"
                                        :helpText="'Choose images or files'"
                                        :errorText="{
                                            type: 'Invalid file type.',
                                            size:
                                                'Files should not exceed 10MB in size'
                                        }"
                                        @select="filesSelected($event)"
                                        @beforedelete="onBeforeDelete($event)"
                                        @delete="fileDeleted($event)"
                                        v-model="fileRecords"
                                    >
                                    </VueFileAgent>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div
                        id="footer"
                        class="app-footer m-0 tw-flex tw-justify-end tw-space-x-2 tw-sh"
                    >
                        <ts-button
                            @click.prevent="() => $emit('cancel')"
                            class="btn-gray"
                        >
                            {{ $t('cancel') }}</ts-button
                        >
                        <ts-button
                            :waiting="waiting"
                            @click.prevent="onUpdateContract"
                            color="primary"
                        >
                            {{ $t('update') }}</ts-button
                        >
                    </div>
                </div>
            </ts-loading-banner>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { isEmpty, forEach } from 'lodash'
import { Errors } from 'form-backend-validation'
import moment from 'moment'
// Vue.use(VueFileAgent)
// import VueFileAgent from 'vue-file-agent'
// import Vue from 'vue'
// import httpClient from '@/http-client'
// import 'vue-file-agent/dist/vue-file-agent.css'
Vue.use(VueFileAgent)
import VueFileAgent from 'vue-file-agent'
import Vue from 'vue'
import httpClient from '@/http-client'
import 'vue-file-agent/dist/vue-file-agent.css'

export default {
    name: 'rental-contract',
    data () {
        return {
            waiting: false,
            loading: false,
            waiting_new: false,
            errors: new Errors(),
            fileRecords: [],
            fileRecordsForUpload: [],
            uploadUrl: process.env.VUE_APP_PROXY + '/api/branch/upload-files',
            uploadHeaders: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-Test-Header': 'vue-file-agent',
                Authorization: 'Bearer ' + this.$store.state.accessToken
            },
            model: {
                office_rentals: [
                    {
                        contract_id: 0,
                        contract_period: null,
                        monthly_fee: null,
                        deposit_amount: null,
                        currency_id: 1,
                        owner_phone_number: null,
                        remarks: null,
                        contract_start_date: null,
                        contract_expiry_date: null,
                        village_code: null,
                        commune_code: null,
                        district_code: null,
                        province_code: null,
                        notification_receiver: [],
                        attached_file: []
                    }
                ]
            },
            province_change: false,
            district_change: false,
            commune_change: false,
            employeeLoading: false,
            districts: [],
            communes: [],
            villages: []
        }
    },
    computed: {
        ...mapState('corporate/branch', [
            'edit_data',
            'currencies',
            'provinces'
        ]),
        isEdit () {
            return !isEmpty(this.edit_data)
        },
        ...mapGetters(['formatNumber']),
        employees () {
            return this.$store.state.corporate.branch.employees
        }
    },
    methods: {
        ...mapActions('corporate/branch', [
            'find',
            'getProvince',
            'getVillage',
            'getDistrict',
            'getCommune',
            'getCurrency',
            'getEmployees',
            'removeFileUploaded'
        ]),
        async fetchResource () {
            this.loading = true
            await this.getCurrency()
            await this.getProvince()
            await this.getVillage()
            await this.getDistrict()
            await this.getCommune()
            await this.getEmployees()
            await this.find(this.$route.params.id).catch(error => {
                this.notice({ type: 'error', text: error.message })
            })
            this.setEditData()
            this.loading = false
        },
        async setEditData () {
            if (!isEmpty(this.edit_data)) {
                this.fileRecords = []
                this.model.office_rentals = []
                this.provinceChange(this.edit_data.province_code)
                this.districtChange(this.edit_data.district_code)
                this.communeChange(this.edit_data.commune_code)
                let details = {
                    contract_id: this.edit_data.contract_id,
                    branch_id: this.edit_data.branch_id,
                    contract_start_date: this.edit_data.contract_start_date,
                    contract_expiry_date: this.edit_data.contract_expiry_date,
                    currency_id: this.edit_data.currency_id,
                    contract_period: this.edit_data.contract_period,
                    monthly_fee: this.edit_data.monthly_fee,
                    deposit_amount: this.edit_data.deposit_amount,
                    owner_phone_number: this.edit_data.owner_phone_number,
                    notification_receiver: this.edit_data.notification_receiver
                        ? this.edit_data.notification_receiver
                              .split(',')
                              .map(function (t) {
                                  return parseInt(t)
                              })
                        : [],
                    province_code: this.edit_data.province_code,
                    district_code: this.edit_data.district_code,
                    commune_code: this.edit_data.commune_code,
                    village_code: this.edit_data.village_code,
                    remarks: this.edit_data.remarks,
                    attached_file: this.edit_data.rental_contract_attachment
                }
                this.model.office_rentals.push(details)
                this.edit_data.rental_contract_attachment.map(element => {
                    this.fileRecords.push({
                        name: element.attached_file_name,
                        type: element.mime_type,
                        size: 209129,
                        text: element.attached_file.split('.').pop(),
                        url: element.attached_file
                    })
                })
            }
        },
        async onUpdateContract () {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch('corporate/branch/updateContract', {
                    id: this.edit_data.contract_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        onChangeContractStartDate (date, item) {
            item.contract_start_date = date
            this.getExpiryDate(item)
        },
        getExpiryDate (item) {
            item.contract_expiry_date = moment(
                moment(item.contract_start_date, 'DD-MM-YYYY').format(
                    'YYYY-MM-DD'
                )
            )
                .add(item.contract_period, 'M')
                .format('DD-MM-YYYY')
        },
        onChangeContractExpiryDate (date, item) {
            item.contract_expiry_date = date
        },
        provinceChange (province_code) {
            this.province_change = true
            this.getDistrict(province_code)
                .then(response => {
                    this.districts = response.data
                })
                // .catch(error => {
                //     this.notice({ type: 'error', text: error.message })
                // })
                .finally(() => {
                    this.province_change = false
                })
        },
        districtChange (district_code) {
            this.district_change = true
            this.getCommune(district_code)
                .then(response => {
                    this.communes = response.data
                })
                // .catch(error => {
                //     this.notice({ type: 'error', text: error.message })
                // })
                .finally(() => {
                    this.district_change = false
                })
        },
        communeChange (commune_code) {
            this.commune_change = true
            this.getVillage(commune_code)
                .then(response => {
                    this.villages = response.data
                })
                // .catch(error => {
                //     this.notice({ type: 'error', text: error.message })
                // })
                .finally(() => {
                    this.commune_change = false
                })
        },
        deleteUploadedFile: function (fileRecord) {
            this.$refs.vueFileAgent.deleteUpload(
                this.uploadUrl,
                this.uploadHeaders,
                fileRecord
            )
        },
        uploadFiles: function () {
            forEach(this.fileRecordsForUpload, f => {
                let frm = new FormData()
                frm.append('file', f.file)
                httpClient
                    .post('/api/branches/upload-files', frm, {
                        onUploadProgress: el => {
                            if (el.lengthComputable) {
                                f.progressing = Math.round(
                                    (el.loaded * 100) / el.total
                                )
                            }
                        }
                    })
                    .then(response => {
                        this.model.office_rentals[0].attached_file.push({
                            attached_file_name:
                                response.data.attached_file_name,
                            attached_file: response.data.attached_file,
                            mime_type: response.data.mime_type,
                            attachment_id: null,
                            contract_id: null,
                            size: null
                        })
                        // this.fileRecords.push({
                        //     attached_file_name:
                        //         response.data.attached_file_name,
                        //     attached_file: response.data.attached_file,
                        //     mime_type: response.data.mime_type,
                        //     attachment_id: null,
                        //     contract_id: null,
                        //     size: null
                        // })
                    })
            })

            this.fileRecordsForUpload = []
        },
        filesSelected: function (fileRecordsNewlySelected) {
            var validFileRecords = fileRecordsNewlySelected.filter(
                fileRecord => !fileRecord.error
            )
            this.fileRecordsForUpload = this.fileRecordsForUpload.concat(
                validFileRecords
            )
            this.uploadFiles()
        },
        onBeforeDelete: function (fileRecord) {
            var i = this.fileRecordsForUpload.indexOf(fileRecord)
            if (i !== -1) {
                this.fileRecordsForUpload.splice(i, 1)
                var k = this.fileRecords.indexOf(fileRecord)
                if (k !== -1) this.fileRecords.splice(k, 1)
            } else {
                if (confirm('Are you sure you want to delete?')) {
                    this.fileDeleted(fileRecord)
                    // this.$refs.vueFileAgent.deleteFileRecord(fileRecord)
                }
            }
        },
        fileDeleted: function (fileRecord) {
            var i = this.fileRecordsForUpload.indexOf(fileRecord)
            var j = this.fileRecords.indexOf(fileRecord)
            console.log(i, j)
            if (i !== -1) {
                this.fileRecordsForUpload.splice(i, 1)
            } else {
                this.fileRecords.splice(j, 1)
                if (fileRecord.file) {
                    let item = this.model.office_rentals[0].attached_file.find(
                        el => el.attached_file_name == fileRecord.file.name
                    )
                    let file_index = this.model.office_rentals[0].attached_file.indexOf(
                        ...this.model.office_rentals[0].attached_file.filter(
                            f => f.attached_file_name == item.attached_file_name
                        )
                    )
                    this.model.office_rentals[0].attached_file.splice(
                        file_index,
                        1
                    )
                } else {
                    let item = this.model.office_rentals[0].attached_file.find(
                        f => f.attached_file == fileRecord.url
                    )
                    let file_index = this.model.office_rentals[0].attached_file.indexOf(
                        ...this.model.office_rentals[0].attached_file.filter(
                            f => f.attached_file == item.attached_file
                        )
                    )
                    this.model.office_rentals[0].attached_file.splice(
                        file_index,
                        1
                    )
                }
            }
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'BRANCH',
                desc: not.text
            })
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchResource()
        })
    }
}
</script>
