var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('branch.pageTitle'),"breadcrumb":[
		{ text: _vm.$t('home'), href: '/' },
		{
			text: _vm.$t('branch.pageTitle'),
			active: true
		}
	]}}),_c('ts-panel',[_c('ts-panel-wrapper',{staticClass:"tw-flex tw-items-center tw-justify-between"},[_c('div',{staticClass:"tw-space-x-2"},[_c('Button',{attrs:{"type":"info","ghost":""},on:{"click":function($event){return _vm.$router.push({ name: 'branch-create' })}}},[_vm._v(_vm._s(_vm.$t("addNew"))+" ")]),_c('Button',{attrs:{"type":"success","loading":_vm.exporting},on:{"click":function($event){$event.preventDefault();return _vm.exportExcel.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-file-excel"}),_vm._v(" "+_vm._s(_vm.$t('export'))+" ")])],1),_c('div',{staticClass:"tw-justify-end tw-space-x-2"},[_c('Input',{style:(_vm.width),attrs:{"search":"","placeholder":_vm.$t('branch.search')},on:{"on-focus":function () { return (_vm.width = 'width: 500px'); },"on-blur":function () { return (_vm.width = 'width: 300px'); }},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.resources,"size":"small","loading":_vm.loading,"max-height":"700","stripe":""},scopedSlots:_vm._u([{key:"rental_contracts_count",fn:function(ref){
	var row = ref.row;
return [(row.rental_contracts_count > 0)?_c('span',{staticClass:"tw-font-bold badge bg-blue"},[_vm._v(" "+_vm._s(row.rental_contracts_count)+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"company_name_en",fn:function(ref){
	var row = ref.row;
return [_vm._v(" "+_vm._s(row.company ? row.company.company_name_en : "")+" ")]}},{key:"company_name_kh",fn:function(ref){
	var row = ref.row;
return [_vm._v(" "+_vm._s(row.company ? row.company.company_name_kh : "")+" ")]}},{key:"action",fn:function(ref){
	var row = ref.row;
return [_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('edit')),expression:"$t('edit')"}],staticClass:"text-primary ml-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onEdit(row)}}},[_c('Icon',{attrs:{"type":"ios-create","size":"20"}})],1),_c('Poptip',{attrs:{"confirm":"","title":_vm.$t('areYouSure'),"transfer":true,"ok-text":_vm.$t('yes'),"cancel-text":_vm.$t('no')},on:{"on-ok":function($event){return _vm.onDelete(row)}}},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('delete')),expression:"$t('delete')"}],staticClass:"ml-2 text-danger",attrs:{"disabled":row._deleting}},[(row._deleting)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('Icon',{attrs:{"type":"ios-trash","size":"20"}})],1)])]}}])}),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-end tw-p-4"},[_c('ts-i-pagination',{on:{"navigate":function($event){return _vm.fetchData({
					filter: {
						has_contract: _vm.has_contract
					}
				})}},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }