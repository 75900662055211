<template>
	<div>
		<ts-page-title :title="$t('branch.pageTitle')" :breadcrumb="[
			{ text: $t('home'), href: '/' },
			{
				text: $t('branch.pageTitle'),
				active: true
			}
		]" />
		<ts-panel>
			<ts-panel-wrapper class="tw-flex tw-items-center tw-justify-between">
				<div class="tw-space-x-2">
					<Button type="info" ghost @click="$router.push({ name: 'branch-create' })">{{ $t("addNew") }}
					</Button>
					<Button type="success" @click.prevent="exportExcel" :loading="exporting">
						<i class="fas fa-file-excel"></i> {{ $t('export') }}
					</Button>
				</div>
				<div class="tw-justify-end tw-space-x-2">
					<Input search v-model="search" :placeholder="$t('branch.search')" :style="width"
						@on-focus="() => (width = 'width: 500px')" @on-blur="() => (width = 'width: 300px')" />
				</div>
			</ts-panel-wrapper>
			<Table :columns="columns" :data="resources" size="small" :loading="loading" max-height="700" stripe>
				<template slot-scope="{ row }" slot="rental_contracts_count">
					<span v-if="row.rental_contracts_count > 0" class="tw-font-bold badge bg-blue">
						{{ row.rental_contracts_count }}
					</span>
					<span v-else>
						-
					</span>
				</template>
				<template slot-scope="{ row }" slot="company_name_en">
					{{ row.company ? row.company.company_name_en : "" }}
				</template>
				<template slot-scope="{ row }" slot="company_name_kh">
					{{ row.company ? row.company.company_name_kh : "" }}
				</template>
				<template slot-scope="{ row }" slot="action">
					<a href="#" class="text-primary ml-2" @click.prevent="onEdit(row)" v-tooltip="$t('edit')">
						<Icon type="ios-create" size="20" />
					</a>
					<Poptip confirm :title="$t('areYouSure')" @on-ok="onDelete(row)" :transfer="true" :ok-text="$t('yes')"
						:cancel-text="$t('no')">
						<a class="ml-2 text-danger" v-tooltip="$t('delete')" :disabled="row._deleting">
							<i class="fas fa-circle-notch fa-spin" v-if="row._deleting"></i>
							<Icon type="ios-trash" size="20" v-else />
						</a>
					</Poptip>
				</template>
			</Table>
			<div class="tw-flex tw-items-center tw-justify-end tw-p-4">
				<ts-i-pagination v-model="pagination" @navigate="
					fetchData({
						filter: {
							has_contract: has_contract
						}
					})
					"></ts-i-pagination>
			</div>
		</ts-panel>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { debounce } from "lodash";
import expandRow from "./table-expand.vue";
import { Errors } from 'form-backend-validation'
import * as FileDownload from 'downloadjs'
import { trim } from 'lodash'
export default {
	name: "branchIndex",
	data() {
		return {
			loading: false,
			exporting: false,
			width: "width: 300px",
			errors: new Errors(),
			has_contract: null
		};
	},
	computed: {
		...mapState("corporate/branch", ["resources", "pagination"]),
		search: {
			get() {
				return this.$store.state.corporate.branch.search;
			},
			set(newValue) {
				this.$store.commit("corporate/branch/SET_SEARCH", newValue);
				this.$store.commit("corporate/branch/RESET_CURRENT_PAGE");
			}
		},
		columns() {
			return [
				{
					type: "expand",
					render: (h, params) => {
						return h(expandRow, {
							props: {
								row: params.row
							}
						});
					},
					width: 30
				},
				{
					title: this.$t("branch.numberOfContract"),
					slot: "rental_contracts_count",
					minWidth: 160,
					align: "center",
					filters: [
						{
							label: "Yes",
							value: true
						},
						{
							label: "No",
							value: false
						}
					],
					filterMultiple: false,
					filterRemote(value) {
						this.has_contract = value;
					}
				},
				{
					title: this.$t("branch.prefixCode"),
					key: "branch_code",
					minWidth: 130,
					sortable: true
				},
				{
					title: this.$t("branch.nameEn"),
					key: "branch_name_en",
					minWidth: 300,
					sortable: true
				},
				{
					title: this.$t("branch.nameKh"),
					key: "branch_name_kh",
					minWidth: 300,
					sortable: true
				},
				{
					title: this.$t("branch.phoneNumber"),
					key: "branch_phone",
					minWidth: 250,
					align: "center",
					sortable: true
				},
				{
					title: this.$t("branch.email"),
					key: "branch_email",
					align: "center",
					minWidth: 250
				},
				{
					title: this.$t("branch.companyNameEn"),
					key: "company_name_en",
					slot: "company_name_en",
					minWidth: 300,
					sortable: true
				},
				{
					title: this.$t("branch.companyNameKh"),
					key: "company_name_kh",
					slot: "company_name_kh",
					minWidth: 300,
					sortable: true
				},
				{
					title: this.$t("actions"),
					key: "action",
					slot: "action",
					fixed: "right",
					align: "center",
					width: 90
				}
			];
		}
	},
	methods: {
		fetchData(attributes) {
			this.loading = true;
			this.$store
				.dispatch("corporate/branch/fetch", attributes)
				.catch(error => {
					this.loading = false;
					this.notice({ type: "error", text: error.message });
				})
				.finally(() => {
					this.loading = false;
				});
		},
		onEdit(record) {
			this.$store.commit("corporate/branch/SET_EDIT_DATA", record);
			this.$router.push({
				name: "branch-edit",
				params: { id: record.branch_id }
			});
		},
		async onDelete(record) {
			record._deleting = true;
			this.$store
				.dispatch("corporate/branch/destroy", record.branch_id)
				.then(response => {
					this.fetchData();
					record._deleting = false;
					this.notice({
						type: response.message_id == 0 ? "success" : "warning",
						text: response.message
					});
				})
				.catch(error => {
					this.notice({ type: "error", text: error.message });
					record._deleting = false;
				});
		},
		exportExcel() {
			this.errors = new Errors()
			this.exporting = true
			this.$store
				.dispatch(
					'corporate/branch/exportExcel')
				.then(response => {
					let filename = trim(
						response.headers['content-disposition']
							.substring(
								response.headers['content-disposition'].indexOf(
									'filename'
								)
							)
							.replace('filename=', ''),
						'"'
					)
					FileDownload(response.data, filename)
				})
				.catch(err => {
					let error = JSON.parse(
						String.fromCharCode.apply(null, new Uint8Array(err))
					)
					this.notice({ type: 'error', text: error.message })
					this.errors = new Errors(error.errors)
				})
				.finally(() => (this.exporting = false))
		},
		notice(not) {
			this.$Notice[not.type]({
				title: "BRANCH",
				desc: not.text
			});
		}
	},
	watch: {
		search: debounce(function (search) {
			this.fetchData({ search });
		}, 500),
		has_contract: debounce(function (value) {
			this.fetchData({
				search: this.search,
				filter: {
					has_contract: value
				}
			});
		}, 500)
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.fetchData();
		});
	},
	beforeRouteLeave(to, from, next) {
		this.$store.commit("corporate/branch/RESET_STATE");
		next();
	}
};
</script>
<style scoped>
.text-nowrap {
	white-space: nowrap;
}
</style>
